import { Layout as ReleasePageBaseLayout } from '@imus/release-page-template';
import type { PageId as ArtistPageId } from '@imus/artist-page-template';
import { Layout as ArtistPageBaseLayout } from '@imus/artist-page-template';
import { useRouter } from 'next/router.js';
import type {
  ReleasePageProps,
  ArtistPublishedData,
} from '@/shared/fetchStaticData';
import { NextSeo } from 'next-seo';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import { useEffect } from 'react';

const ReleaseContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  container-name: template-sizer;
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const ArtistContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  container-name: artist-template-sizer;
  container-type: inline-size;
`;

export function ReleasePageLayout({
  data,
  pageId,
  children,
}: ReleasePageProps & { children: React.ReactNode }) {
  // _props: InferGetStaticPropsType<typeof getStaticProps>
  const router = useRouter();
  const { t } = useTranslation();

  if (data.release?.cover?.url) {
    data.release.cover.url = `${process.env.NEXT_PUBLIC_ARTIST_HUB_DATA_SOURCE_URL}/a/${pageId}/cover.jpg`;
  }

  const description = t('page-description', {
    title: data.release.title,
    releaseTitle: data.release.title,
    releaseArtist: data.release.displayArtist,
  });
  const title = data.release.title;
  useEffect(() => {
    router.prefetch(`/a/${pageId}/`);
  }, []);

  const baseUrl = '/a/' + pageId;

  return (
    <ReleaseContainer>
      <ReleasePageBaseLayout baseUrl={baseUrl} data={data}>
        <NextSeo
          description={description}
          title={title}
          openGraph={{
            title,
            description,
            locale: data.general.locale,
            images: data.release.cover?.url
              ? [{ url: data.release.cover?.url }]
              : [],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
        {children}
      </ReleasePageBaseLayout>
    </ReleaseContainer>
  );
}

export function ArtistPageLayout({
  data,
  pageId,
  fileBaseUrl,
  children,
}: {
  data: ArtistPublishedData;
  fileBaseUrl: string;
  pageId: string;
  children: React.ReactNode;
}) {
  // _props: InferGetStaticPropsType<typeof getStaticProps>
  const router = useRouter();
  const { t } = useTranslation();
  const description = t('artist-page-description', {
    context: data.branded ? 'branded' : null,
    description: data.description,
  });
  const title = t('artist-page-title', {
    name: data.name,
    context: data.branded ? 'branded' : null,
  });

  useEffect(() => {
    console.log('Artist Hub Version: ' + process.env.APP_VERSION);
    router.prefetch(`/artist/${pageId}/`);
    router.prefetch(`/artist/${pageId}/releases`);
    router.prefetch(`/artist/${pageId}/tour`);
    router.prefetch(`/artist/${pageId}/press`);
  }, []);

  return (
    <ArtistContainer>
      <ArtistPageBaseLayout
        fileBaseUrl={fileBaseUrl}
        baseUrl={'/artist/' + pageId}
        data={data}
        page={(router.route.split('/')[3] as ArtistPageId) || 'about'}
        onNavigation={(e: any, page: any) => {
          e.preventDefault();
          router.push(
            `/artist/${pageId}/${page === 'about' ? '' : page}`,
            undefined,
            { shallow: true }
          );
        }}
      >
        <NextSeo
          description={description}
          title={title}
          openGraph={{
            title,
            description,
            locale: data.locale,
            images: data.profileImage
              ? [{ url: fileBaseUrl + '/' + data.profileImage.filename }]
              : [],
          }}
          twitter={{
            cardType: 'summary_large_image',
          }}
        />
        {children}
      </ArtistPageBaseLayout>
    </ArtistContainer>
  );
}
