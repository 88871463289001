import { css } from '@emotion/react';

export const baseColors = css`
  /* OLD */
  --black-95: rgba(0, 0, 0, 0.95);
  --black-65: rgba(0, 0, 0, 0.65);
  --black-35: rgba(0, 0, 0, 0.35);
  --black-10: rgba(0, 0, 0, 0.1);

  --white-95: rgba(255, 255, 255, 0.95);
  --white-65: rgba(255, 255, 255, 0.65);
  --white-35: rgba(255, 255, 255, 0.35);
  --white-10: rgba(255, 255, 255, 0.1);

  --gray-100: #ffffff;
  --gray-95: #fafafa;
  --gray-90: #f4f4f4;
  --gray-85: #eeeeee;
  --gray-33: #333333;
  --gray-20: #272727;
  --gray-15: #212121;
  --gray-10: #1a1a1a;

  /* Vibrant */
  /* color */
  --bonbon-10: #3e0020;
  --bonbon-10-5: #3e00200d;
  --bonbon-25: #780044;
  --bonbon-40: #b8006b;
  --bonbon-60-10: #fe489d1a;
  --bonbon-60-5: #fe489d0d;
  --bonbon-99: #fffbff;
  --bonbon-99-5: #fffbff0d;
  --brand-alabaster: #e8eddf;
  --brand-black: #0a0908;
  --brand-midnight-green-30: #004e64;
  --brand-rose-bonbon-60: #ff499e;
  --brand-screamin-green-90: #73fc8a;
  --brand-sunglow-82: #fdca40;
  --midnight-green-10: #001f29;
  --midnight-green-10-5: #001f290d;
  --midnight-green-25: #004154;
  --midnight-green-30-10: #004d631a;
  --midnight-green-30-5: #004d630d;
  --midnight-green-40: #2a657b;
  --midnight-green-99: #fafcff;
  --midnight-green-99-5: #fafcff0d;
  --new-gray-10: #1b1c1b;
  --new-gray-10-8: #1b1c1b14;
  --new-gray-10-5: #1b1c1b0d;
  --new-gray-10-80: #1b1c1bcc;
  --new-gray-15: #262625;
  --new-gray-20: #303030;
  --new-gray-20-90: #303030e5;
  --new-gray-25: #3c3b3b;
  --new-gray-30: #474746;
  --new-gray-40: #5f5e5d;
  --new-gray-47: #71706f;
  --new-gray-64: #9e9c9b;
  --new-gray-82: #cdcbc9;
  --new-gray-88: #dfdcdb;
  --new-gray-88-80: #dfdcdbcc;
  --new-gray-92: #e9e7e5;
  --new-gray-95: #f4f1f0;
  --new-gray-95-90: #f4f1f0e5;
  --new-gray-99: #fffcfa;
  --new-gray-99-8: #fffcfa14;
  --new-gray-99-5: #fffcfa0d;
  --orange-10: #341100;
  --orange-10-8: #34110014;
  --orange-50: #c75400;
  --orange-60: #f06700;
  --orange-99: #fffbff;
  --orange-99-8: #fffbff14;
  --red-orange-10: #410002;
  --red-orange-10-8: #41000214;
  --red-orange-50: #e32e2a;
  --red-orange-60: #ff5449;
  --red-orange-99: #fffbff;
  --red-orange-99-8: #fffbff14;
  --screamin-green-10: #002107;
  --screamin-green-10-5: #0021070d;
  --screamin-green-65: #1fb44c;
  --screamin-green-82: #5de576;
  --screamin-green-90-10: #76fe8b1a;
  --screamin-green-90-5: #76fe8b0d;
  --screamin-green-99: #f6fff1;
  --screamin-green-99-5: #f6fff10d;
  --sunglow-10: #251a00;
  --sunglow-10-5: #251a000d;
  --sunglow-65: #c39600;
  --sunglow-82: #fcc30f;
  --sunglow-82-10: #fcc30f1a;
  --sunglow-82-5: #fcc30f0d;
  --sunglow-99: #fffbff;
  --sunglow-99-5: #fffbff0d;
`;

export const DARK_MODE_COLORS = css`
  --background: var(--new-gray-10);
  --inverted-on-surface: var(--new-gray-10);
  --inverted-surface: var(--new-gray-99);
  --on-surface: var(--new-gray-99);
  --on-surface-var: var(--new-gray-82);
  --on-surface-semi: var(--new-gray-99-5);
  --on-surface-var-2: var(--new-gray-64);
  --outline: var(--new-gray-47);
  --outline-var: var(--new-gray-30);
  --semi-background: var(--new-gray-10-80);
  --semi-surface-container-medium: var(--new-gray-20-90);
  --state-active-on-surface: var(--new-gray-99-8);
  --state-hover-on-surface: var(--new-gray-99-5);
  --surface-container-high: var(--new-gray-25);
  --surface-container-low: var(--new-gray-15);
  --surface-container-medium: var(--new-gray-20);

  --primary-accent: var(--brand-screamin-green-90);
  --primary-accent-surface-container: var(--screamin-green-10);
  --primary-on-accent: var(--screamin-green-10);
  --primary-on-accent-surface: var(--screamin-green-99);
  --primary-on-accent-surface-var: var(--screamin-green-82);
  --primary-on-accent-surface-var-2: var(--screamin-green-65);
  --primary-state-active-accent: var(--screamin-green-90-10);
  --primary-state-hover-accent: var(--screamin-green-90-5);
  --primary-state-hover-on-accent: var(--screamin-green-10-5);
  --primary-state-hover-on-accent-surface: var(--screamin-green-99-5);

  --tertiary-accent: var(--brand-sunglow-82);
  --tertiary-accent-surface-container: var(--sunglow-10);
  --tertiary-on-accent: var(--sunglow-10);
  --tertiary-on-accent-surface: var(--sunglow-99);
  --tertiary-on-accent-surface-var: var(--sunglow-82);
  --tertiary-on-accent-surface-var-2: var(--sunglow-65);
  --tertiary-state-active-accent: var(--sunglow-82-10);
  --tertiary-state-hover-accent: var(--sunglow-82-5);
  --tertiary-state-hover-on-accent: var(--sunglow-10-5);
  --tertiary-state-hover-on-accent-surface: var(--sunglow-99-5);

  --error-error: var(--red-orange-60);
  --error-error-surface-container: var(--red-orange-10);
  --error-on-error-surface: var(--red-orange-99);
  --error-state-hover-on-error-surface: var(--red-orange-99-8);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);
  --surface: rgba(255, 255, 255, 0.1);
`;

export const LIGHT_MODE_COLORS = `
  --background: var(--new-gray-88);
  --inverted-on-surface: var(--new-gray-95);
  --inverted-surface: var(--new-gray-25);
  --on-surface: var(--new-gray-10);
  --on-surface-semi: var(--new-gray-10-5);
  --on-surface-var: var(--new-gray-25);
  --on-surface-var-2: var(--new-gray-40);
  --outline: var(--new-gray-64);
  --outline-var: var(--new-gray-82);
  --semi-background: var(--new-gray-88-80);
  --semi-surface-container-medium: var(--new-gray-95-90);
  --state-active-on-surface: var(--new-gray-10-8);
  --state-hover-on-surface: var(--new-gray-10-5);
  --surface-container-high: var(--new-gray-99);
  --surface-container-low: var(--new-gray-92);
  --surface-container-medium: var(--new-gray-95);

  --primary-accent: var(--brand-midnight-green-30);
  --primary-accent-surface-container: var(--midnight-green-99);
  --primary-on-accent: var(--midnight-green-99);
  --primary-on-accent-surface: var(--midnight-green-10);
  --primary-on-accent-surface-var: var(--midnight-green-25);
  --primary-on-accent-surface-var-2: var(--midnight-green-40);
  --primary-state-active-accent: var(--midnight-green-30-10);
  --primary-state-hover-accent: var(--midnight-green-30-5);
  --primary-state-hover-on-accent: var(--midnight-green-99-5);
  --primary-state-hover-on-accent-surface: var(--midnight-green-10-5);

  --tertiary-accent: var(--brand-rose-bonbon-60);
  --tertiary-accent-surface-container: var(--bonbon-99);
  --tertiary-on-accent: var(--bonbon-99);
  --tertiary-on-accent-surface: var(--bonbon-10);
  --tertiary-on-accent-surface-var: var(--bonbon-25);
  --tertiary-on-accent-surface-var-2: var(--bonbon-40);
  --tertiary-state-active-accent: var(--bonbon-60-10);
  --tertiary-state-hover-accent: var(--bonbon-60-5);
  --tertiary-state-hover-on-accent: var(--bonbon-99-5);
  --tertiary-state-hover-on-accent-surface: var(--bonbon-10-5);

  --fg-1: var(--on-surface);
  --fg-2: var(--on-surface-var);
  --fg-3: var(--on-surface-var-2);
  --fg-4: var(--outline-var);

  --bg-4: var(--background);
  --bg-3: var(--surface-container-low);
  --bg-2: var(--surface-container-medium);
  --bg-1: var(--surface-container-high);

  --error-error: var(--red-orange-50);
  --error-error-surface-container: var(--red-orange-99);
  --error-on-error-surface: var(--red-orange-10);
  --error-state-hover-on-error-surface: var(--red-orange-10-8);

  --surface: rgba(255, 255, 255, 0.50);

`;

export const errorThemes = {
  error: css`
    --error: var(--error-error);
    --error-surface-container: var(--error-error-surface-container);
    --error-container: var(--error-error-surface-container);
    --on-error-surface: var(--error-on-error-surface);
  `,
};

export const accentThemes = {
  primary: css`
    --accent: var(--primary-accent);
    --accent-surface-container: var(--primary-accent-surface-container);
    --on-accent: var(--primary-on-accent);
    --on-accent-surface: var(--primary-on-accent-surface);
    --on-accent-surface-var: var(--primary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--primary-on-accent-surface-var-2);
    --on-error-surface: var(--primary-on-error-surface);
    --state-active-accent: var(--primary-state-active-accent);
    --state-hover-accent: var(--primary-state-hover-accent);
    --state-hover-on-accent: var(--primary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --primary-state-hover-on-accent-surface
    );
    --state-hover-on-error-surface: var(--primary-state-hover-on-error-surface);
  `,
  secondary: css`
    --accent: var(--primary-accent);
    --accent-surface-container: var(--primary-accent-surface-container);
    --on-accent: var(--primary-on-accent);
    --on-accent-surface: var(--primary-on-accent-surface);
    --on-accent-surface-var: var(--primary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--primary-on-accent-surface-var-2);
    --on-error-surface: var(--primary-on-error-surface);
    --state-active-accent: var(--primary-state-active-accent);
    --state-hover-accent: var(--primary-state-hover-accent);
    --state-hover-on-accent: var(--primary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --primary-state-hover-on-accent-surface
    );
    --state-hover-on-error-surface: var(--primary-state-hover-on-error-surface);
  `,
  tertiary: css`
    --accent: var(--tertiary-accent);
    --accent-surface-container: var(--tertiary-accent-surface-container);
    --error: var(--tertiary-error);
    --error-surface-container: var(--tertiary-error-surface-container);
    --on-accent: var(--tertiary-on-accent);
    --on-accent-surface: var(--tertiary-on-accent-surface);
    --on-accent-surface-var: var(--tertiary-on-accent-surface-var);
    --on-accent-surface-var-2: var(--tertiary-on-accent-surface-var-2);
    --on-error-surface: var(--tertiary-on-error-surface);
    --state-active-accent: var(--tertiary-state-active-accent);
    --state-hover-accent: var(--tertiary-state-hover-accent);
    --state-hover-on-accent: var(--tertiary-state-hover-on-accent);
    --state-hover-on-accent-surface: var(
      --tertiary-state-hover-on-accent-surface
    );
    --state-hover-on-error-surface: var(
      --tertiary-state-hover-on-error-surface
    );
  `,
};
export const secondaryTheme = accentThemes.secondary;
export const primaryTheme = accentThemes.primary;
export const tertiaryTheme = accentThemes.tertiary;

export const darkTheme = DARK_MODE_COLORS;

export const baseTheme = css`
  ${baseColors}
  ${errorThemes.error}

  ${primaryTheme}

  --artist-hub: #b151c9;
  --music-distribution: #16ad40;
  --instant-mastering: #ffa800;

  --sub-amplify: #3cbeb1;
  --sub-amplify-plus: #926ef7;
  --sub-amplify-pro: #e4497d;

  --accent-green: #82bd32;
  --accent-orange: #fb9013;

  --state-positive: #00c82c;
  --state-negative: var(--error);

  --fg-inverted: var(--white-95);

  --bg-inverted: var(--gray-10);
`;
