import styled from '@emotion/styled';
import { motion, useAnimate } from 'framer-motion';
import { reverse } from 'ramda';
import React, { memo, useEffect } from 'react';
import { themeLight as theme } from '../../../theme';

interface Props {
  loading?: boolean;
  indeterminate?: boolean;
  progress?: number;
  size?: 'small' | 'large';
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OldLoadingIndicator = ({
  indeterminate = true,
  progress = 25,
  size = 'small',
  color,
  className,
  style,
}: Props): JSX.Element => {
  const svgSize = size === 'large' ? 48 : 24;

  return (
    <Wrapper
      data-test-id="LoadingIndicator"
      className={className}
      style={style}
    >
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        width={svgSize}
        height={svgSize}
      >
        <motion.circle
          cx={12}
          cy={12}
          r={9}
          fill="none"
          style={{ originX: '12px', originY: '12px' }}
          stroke={color || theme.foreground.primary}
          strokeWidth={2}
          strokeDasharray={Math.PI * 18}
          strokeDashoffset={
            indeterminate ? 24 : Math.PI * 18 * (1 - progress / 100)
          }
          strokeLinecap="round"
          animate={{ rotate: indeterminate ? 360 : 0 }}
          transition={{
            repeatType: 'loop',
            repeat: Infinity,
            duration: 2.4,
            ease: 'linear',
          }}
        />
      </svg>
    </Wrapper>
  );
};

const FRAME_DUR = 1 / 14;

const bars = reverse(['one', 'two', 'three', 'four', 'five', 'six']);

const frames = [
  [2, 2, 2, 2, 2, 2],
  [2, 2, 2, 2, 2, 4],
  [2, 2, 2, 2, 4, 6],
  [2, 2, 2, 4, 6, 12],

  [2, 2, 4, 6, 12, 16],
  [2, 4, 6, 12, 16, 16],
  [4, 6, 12, 16, 16, 12],
  [6, 12, 16, 16, 12, 6],

  [12, 16, 16, 12, 6, 4],
  [16, 16, 12, 6, 4, 2],
  [16, 12, 6, 4, 2, 2],
  [12, 6, 4, 2, 2, 2],

  [6, 4, 2, 2, 2, 2],
  [4, 2, 2, 2, 2, 2],
  [2, 2, 2, 2, 2, 2],
];

const animateConfig = frames.reduce(
  (acc, f) => [
    ...acc,
    ...f.map((size, idx) => [
      `rect.${bars[idx]}`,
      {
        height: size,
      },
      {
        at: idx > 0 ? '<' : '',
        duration: FRAME_DUR,
      },
    ]),
  ],
  [] as any
);

// [
//   'path.five',
//   { d: 'M9 11H11V13H9V11Z' },
//   { at: '<', repeat: Infinity, duration: FRAME_DUR },
// ]
function useLoadingAnimation() {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate([...animateConfig], { repeat: Infinity });
  }, []);

  return scope;
}

const Rect = styled.rect`
  transform: translate(0, -50%);
  transform-origin: center;
  transform-box: fill-box;
`;

export const NewLoading = ({ color = 'var(--on-surface)' }: Props) => {
  const scope = useLoadingAnimation();
  return (
    <svg
      ref={scope}
      className="loader"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Rect fill={color} y="50%" className="one" width="2" height="2" x="1" />
      <Rect fill={color} y="50%" className="two" width="2" height="2" x="5" />
      <Rect fill={color} y="50%" className="three" width="2" height="2" x="9" />
      <Rect fill={color} y="50%" className="four" width="2" height="2" x="13" />
      <Rect fill={color} y="50%" className="five" width="2" height="2" x="17" />
      <Rect fill={color} y="50%" className="six" width="2" height="2" x="21" />
    </svg>
  );
};

export default memo<Props>(NewLoading);
